/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form } from 'antd';
import { Store } from 'react-notifications-component';
import MUITextField from '../../common/material-ui-components/MUITextField';
import { postToApi } from '../../apis/apis';
import EstateDetailsContext from '../../contexts/estate-details-context/EstateDetailsContext';
import './NewItem.scss';
import './InfoForm.scss';

const schema = Yup.object().shape({
  name: Yup.string().required('من فضلك ادخل الاسم'),
  piece_number: Yup.string().required('من فضلك ادخل الرقم الارض'),
  estate_area: Yup.string()
    .required('من فضلك ادخل مساحه الارض')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام')
    .test('estate_area', 'من فضلك ادخل القيمة', (v) => {
      let result = true;
      if (v <= 0) {
        result = false;
      }
      return result;
    }),
  meter_price: Yup.string()
    .required('من فضلك ادخل سعر المتر')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام')
    .test('meter_price', 'من فضلك ادخل القيمة', (v) => {
      let result = true;
      if (v <= 0) {
        result = false;
      }
      return result;
    }),
  estate_tax: Yup.string()
    .notRequired()
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام')
    .test('estate_tax', 'من فضلك ادخل القيمة', (v) => {
      let result = true;
      if (v && v < 0) {
        result = false;
      }
      return result;
    }),
  pursuit_ratio: Yup.string()
    .notRequired()
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام')
    .test('pursuit_ratio', 'من فضلك ادخل القيمة', (v) => {
      let result = true;
      if (v && v <= 0) {
        result = false;
      }
      return result;
    }),
  additional_tax: Yup.string()
    .notRequired()
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام')
    .test('additional_tax', 'من فضلك ادخل القيمة', (v) => {
      let result = true;
      if (v && v < 0) {
        result = false;
      }
      return result;
    }),
  total: Yup.string()
    .notRequired()
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام')
    .test('total', 'من فضلك ادخل القيمة', (v) => {
      let result = true;
      if (v && v <= 0) {
        result = false;
      }
      return result;
    })
});

const calcTotal = (area, price, tax, pursuitTax, additionalTax) => {
  let calcNetPrice = 0;
  let calcTaxPrice = 0;
  let calcPursuitTaxPrice = 0;
  let calcAdditionalTax = 0;
  if (!isNaN(area) && !isNaN(calcTaxPrice)) {
    calcNetPrice = area * price;
  }
  if (!isNaN(area) && !isNaN(calcTaxPrice) && !isNaN(tax)) {
    calcTaxPrice = (calcNetPrice * tax) / 100;
  }
  if (!isNaN(area) && !isNaN(calcTaxPrice) && !isNaN(pursuitTax)) {
    calcPursuitTaxPrice = (calcNetPrice * pursuitTax) / 100;
  }
  if (!isNaN(area) && !isNaN(calcTaxPrice) && !isNaN(pursuitTax) && !isNaN(additionalTax)) {
    calcAdditionalTax = (calcPursuitTaxPrice * additionalTax) / 100;
  }
  const total = calcNetPrice + calcTaxPrice + calcPursuitTaxPrice + calcAdditionalTax;

  if (!isNaN(total)) return total.toFixed(2);

  return '';
};

const Infoform = () => {
  const { fetchedDetails, setNewEstate, setFetchCount } = useContext(EstateDetailsContext);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      piece_number: '',
      estate_area: '',
      estate_price: '',
      meter_price: '',
      estate_tax: '',
      pursuit_ratio: '',
      additional_tax: '',
      total: ''
    }
  });

  const [calculatedTotal, setCalculatedTotal] = useState('');
  useEffect(() => {
    setCalculatedTotal(
      calcTotal(
        parseFloat(watch('estate_area')),
        parseFloat(watch('meter_price')),
        parseFloat(watch('estate_tax')),
        parseFloat(watch('pursuit_ratio')),
        parseFloat(watch('additional_tax'))
      )
    );
  }, [
    watch('estate_area'),
    watch('meter_price'),
    watch('estate_tax'),
    watch('pursuit_ratio'),
    watch('additional_tax')
  ]);

  useEffect(() => {
    if (parseFloat(calculatedTotal) > 0) {
      setValue('total', calculatedTotal);
    }
  }, [calculatedTotal]);

  const [isEditable, setIsEditable] = useState(false);
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      if (fetchedDetails?.data?.id) {
        formData.append('id', fetchedDetails.data?.id);
      }
      formData.append('name', data?.name);
      formData.append('piece_number', data?.piece_number);
      formData.append('estate_area', data?.estate_area);
      formData.append('meter_price', data?.meter_price);
      formData.append('estate_tax', data?.estate_tax);
      formData.append('pursuit_ratio', data?.pursuit_ratio);
      formData.append('additional_tax', data?.additional_tax);
      //   formData.append('total', data.total);

      const res = await postToApi('/save-estate', formData);
      if (res.response_code !== 200) {
        Store.addNotification({
          title: 'حدث خطأ اثناء التعديل',
          message: res?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.response_code === 200) {
        setFetchCount((prev) => prev + 1);
        Store.addNotification({
          title: ' تم التعديل بنجاح',
          message: res?.message || 'البيانات المدخلة صحيحة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // console.log('user : ', user);

  const [form] = Form.useForm();

  //   updateMeterPrice
  const confirmSatate = async () => {
    try {
      const formData = new FormData();
      formData.append('id', fetchedDetails?.data?.id);
      //   formData.append('total', watch('total'));

      const res = await postToApi('/estate-activation', formData);
      if (res.response_code !== 200) {
        Store.addNotification({
          title: 'حدث خطأ اثناء التعديل',
          message: res?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.response_code === 200) {
        setFetchCount((prev) => prev + 1);
        setNewEstate(true);
        Store.addNotification({
          title: ' تم التعديل بنجاح',
          message: res?.message || 'البيانات المدخلة صحيحة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const updateTotal = async () => {
    try {
      const formData = new FormData();
      formData.append('id', fetchedDetails?.data?.id);
      formData.append('total', watch('total'));

      const res = await postToApi('/update-estate-total', formData);
      if (res.response_code !== 200) {
        Store.addNotification({
          title: 'حدث خطأ اثناء التعديل',
          message: res?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.response_code === 200) {
        setIsEditable(false);
        setFetchCount((prev) => prev + 1);
        Store.addNotification({
          title: ' تم التعديل بنجاح',
          message: res?.message || 'البيانات المدخلة صحيحة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    if (fetchedDetails?.data) {
      const {
        name,
        piece_number,
        estate_area,
        meter_price,
        estate_price,
        estate_tax,
        pursuit_ratio,
        additional_tax,
        total
      } = fetchedDetails?.data;
      setValue('name', name ? name : '');
      setValue('piece_number', piece_number ? piece_number : '');
      setValue('estate_area', estate_area ? parseFloat(estate_area) : '');
      setValue('estate_price', estate_price ? parseFloat(estate_price) : '');
      setValue('meter_price', meter_price ? parseFloat(meter_price) : '');
      setValue('estate_tax', estate_tax ? parseFloat(estate_tax) : '');
      setValue('pursuit_ratio', pursuit_ratio ? parseFloat(pursuit_ratio) : '');
      setValue('additional_tax', additional_tax ? parseFloat(additional_tax) : '');
      setValue('total', total ? parseFloat(total).toFixed(2) : '');
    } else {
      reset({
        name: '',
        piece_number: '',
        estate_area: '',
        estate_price: '',
        meter_price: '',
        estate_tax: '',
        pursuit_ratio: '',
        additional_tax: '',
        total: ''
      });
    }
  }, [fetchedDetails, setValue]);

  const calcAreaPrice = (area, meterPrice) => {
    return parseFloat(area) * parseFloat(meterPrice);
  };
  const calcMeterPrice = (area, areaPrice) => {
    return parseFloat(areaPrice) / parseFloat(area);
  };

  useEffect(() => {
    if (watch('estate_area') && watch('meter_price'))
      setValue('estate_price', calcAreaPrice(watch('estate_area'), watch('meter_price')));
    else setValue('estate_price', '');
  }, [watch('estate_area'), watch('meter_price')]);

  useEffect(() => {
    if (watch('estate_area') && watch('estate_price'))
      setValue('meter_price', calcMeterPrice(watch('estate_area'), watch('estate_price')));
    else setValue('meter_price', '');
  }, [watch('estate_area'), watch('estate_price')]);

  return (
    <Form
      className="login-form data-form info-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <MUITextField
          name="name"
          type="text"
          placeholder="إســم الأرض"
          label="إســم الأرض"
          errorMsg={errors?.name?.message}
          validateStatus={errors?.name ? 'error' : ''}
          control={control}
        />
        <MUITextField
          name="piece_number"
          type="text"
          placeholder="رقم القطعه"
          label="رقم القطعه"
          errorMsg={errors?.piece_number?.message}
          validateStatus={errors?.piece_number ? 'error' : ''}
          control={control}
        />
        <MUITextField
          name="estate_area"
          type="text"
          placeholder="مساحه القطعه"
          label="مساحه القطعه"
          errorMsg={errors?.estate_area?.message}
          validateStatus={errors?.estate_area ? 'error' : ''}
          control={control}
        />
        {/* <div className="two"> */}
        <div className="input-price-wrap">
          <MUITextField
            name="meter_price"
            type="text"
            placeholder="سعر المتر المربع"
            label="سعر المتر المربع"
            errorMsg={errors?.meter_price?.message}
            validateStatus={errors?.meter_price ? 'error' : ''}
            control={control}
          />

          <MUITextField
            name="estate_price"
            type="text"
            placeholder="سعر القطعة الصافى"
            label="سعر القطعة الصافى"
            errorMsg={errors?.estate_price?.message}
            validateStatus={errors?.estate_price ? 'error' : ''}
            control={control}
          />

          {/* {watch('meter_price') && watch('estate_area') ? (
              <div className="price-wrap">
                <div className="title">سعر القطعة الصافى</div>
                <div className="value">
                  {parseFloat(watch('estate_area')) * parseFloat(watch('meter_price')).toFixed(2)}
                </div>
              </div>
            ) : null} */}
        </div>

        {/* </div> */}

        <div className="input-price-wrap">
          <MUITextField
            name="estate_tax"
            type="text"
            // disabled={true}
            placeholder="ضريبة القيمة المضافة"
            label="ضريبة القيمة المضافة"
            errorMsg={errors?.estate_tax?.message}
            validateStatus={errors?.estate_tax ? 'error' : ''}
            control={control}
          />
          {watch('meter_price') && watch('estate_area') && watch('estate_tax') ? (
            <div className="price-wrap">
              <div className="title">قيمة الضريبة</div>
              <div className="value">
                {(
                  (parseFloat(watch('estate_area')) *
                    parseFloat(watch('meter_price')) *
                    parseFloat(watch('estate_tax'))) /
                  100
                ).toFixed(2)}
              </div>
            </div>
          ) : null}
        </div>

        <div className="input-price-wrap">
          <MUITextField
            name="pursuit_ratio"
            type="text"
            placeholder="نسبة السعي"
            label="نسبة السعي"
            errorMsg={errors?.pursuit_ratio?.message}
            validateStatus={errors?.pursuit_ratio ? 'error' : ''}
            control={control}
          />
          {watch('meter_price') && watch('estate_area') && watch('pursuit_ratio') ? (
            <div className="price-wrap">
              <div className="title">سعر نسبة السعى</div>
              <div className="value">
                {(
                  (parseFloat(watch('estate_area')) *
                    parseFloat(watch('meter_price')) *
                    parseFloat(watch('pursuit_ratio'))) /
                  100
                ).toFixed(2)}
              </div>
            </div>
          ) : null}
        </div>
        <div className="input-price-wrap">
          <MUITextField
            name="additional_tax"
            type="text"
            disabled={true}
            placeholder="القيمه المضافه للسعى"
            label="القيمه المضافه للسعى"
            errorMsg={errors?.additional_tax?.message}
            validateStatus={errors?.additional_tax ? 'error' : ''}
            control={control}
          />
          {watch('meter_price') &&
          watch('estate_area') &&
          watch('pursuit_ratio') &&
          watch('additional_tax') ? (
            <div className="price-wrap">
              <div className="title">القيمة المضافة للسعى</div>
              <div className="value">
                {(
                  (((parseFloat(watch('estate_area')) *
                    parseFloat(watch('meter_price')) *
                    parseFloat(watch('pursuit_ratio'))) /
                    100) *
                    parseFloat(watch('additional_tax'))) /
                  100
                ).toFixed(2)}
              </div>
            </div>
          ) : null}
        </div>

        <div className="two">
          <MUITextField
            disabled={!isEditable}
            name="total"
            type="text"
            placeholder="إجمالى السعر"
            label="إجمالى السعر"
            errorMsg={errors?.total?.message}
            validateStatus={errors?.total ? 'error' : ''}
            control={control}
          />
          <div
            className="edit-save-wrap"
            style={{
              display: 'flex',
              gap: 4,
              alignItems: 'center'
            }}>
            <div className="edite" onClick={() => setIsEditable((prev) => !prev)}>
              {isEditable ? 'إلغاء' : 'تعديل'}
            </div>
            {isEditable && (
              <div
                className="edite"
                onClick={updateTotal}
                style={{
                  backgroundColor: '#68b88b'
                }}>
                حفظ
              </div>
            )}
          </div>
        </div>

        <div className="save-end-btns">
          <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
            حفظ
          </Button>

          <button type="button" className="end-auction-btn" onClick={confirmSatate}>
            إنهاء المزاد
          </button>
        </div>
      </div>
    </Form>
  );
};

export default Infoform;
